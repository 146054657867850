import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { globalHistory as history } from "@reach/router";
import useSiteMetadata from "../components/SiteMetadata";

export const BlogPostTemplate = ({
  featuredImage,
  featuredImageAlt,
  content,
  contentComponent,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content
  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <figure className="image">
              <img src={!!featuredImage.childImageSharp ? featuredImage.childImageSharp.fluid.src : featuredImage} alt={featuredImageAlt} />
            </figure>
            <h1 className="title is-size-2 has-text-centered has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <div className="is-size-4">Tags</div>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  featuredImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  featuredImageAlt: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { siteUrl } = useSiteMetadata();
  const { location } = history;

  let currentOrigin = siteUrl;
  try {
    if (!!location.origin) {
      currentOrigin = location.origin;
    }
  } catch (e) {}

  const { markdownRemark: post } = data
  return (
    <Layout>
      <BlogPostTemplate
        featuredImage={post.frontmatter.featuredImage}
        featuredImageAlt={post.frontmatter.featuredImageAlt}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Schoolhouse Blog">
            <title>{post.frontmatter.title}</title>
            <meta name="description" content={post.frontmatter.description} />
            <meta property="og:title" content={`${post.frontmatter.title} | Schoolhouse Blog`} />
            <meta property="og:description" content={post.frontmatter.description} />
            <meta property="og:image" content={`${currentOrigin}${!!post.frontmatter.featuredImage.childImageSharp ? post.frontmatter.featuredImage.childImageSharp.fluid.src : post.frontmatter.featuredImage}`} />
            <meta name="twitter:title" content={`${post.frontmatter.title} | Schoolhouse Blog`} />
            <meta name="twitter:description" content={post.frontmatter.description} />
            <meta name="twitter:image" content={`${currentOrigin}${!!post.frontmatter.featuredImage.childImageSharp ? post.frontmatter.featuredImage.childImageSharp.fluid.src : post.frontmatter.featuredImage}`} />
            <meta name="twitter:image:alt" content={post.frontmatter.featuredImageAlt} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImageAlt
        description
        tags
      }
    }
  }
`
